<template>
  <section class="home" id="home">
        <AppHeader />
        <Featured style="display: none"/>
        <List />
        <Licensions />
        <Youtube />
        <Contact />
  </section>
</template>

<script>
import AppHeader from '@/components/AppHeader'
import Featured from '@/components/Featured'
import List from '@/components/List'
import Licensions from '@/components/Licensions'
import Youtube from '@/components/Youtube'
import Contact from '@/components/Contact'


export default {
    name: "Home",
    components: {
        AppHeader,
        Featured,
        List,
        Licensions,
        Youtube,
        Contact
    }
}
</script>

<style lang="scss" scoped>
.home {
    width: 100vw;
    height: auto;
    transform: translateX(0);
}
</style>