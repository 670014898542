<template>
  <section class="header">
      <router-link to="/" class="logo"><icon-base iconColor="rgb(160, 160, 160)" stroke="rgb(160, 160, 160)" width="50" height="54" viewBox="0 0 141 160"><icon-logo /></icon-base></router-link>


      <div class="header__banner">
          <img src="@/assets/img/studio.jpg" ref="img"  alt="header studio image">
        <h1 ref="header">
          Empe Beats.
        </h1>
      </div>
  </section>
</template>

<script>
import IconBase from '@/assets/icons/IconBase'
import IconLogo from '@/assets/icons/IconLogo'

export default {
    name: "AppHeader",
    components: {
      IconBase,
      IconLogo
    },
    mounted() {
      window.addEventListener('scroll', this.scrollEv)
      
    },
    data() {
      return {
        video: null,
        news: [],
        newsWidth: null,
        pageScroll: null
      }
    },
    methods: {
      scrollEv(){
        this.pageScroll = window.pageYOffset



        if(this.$refs.header) {
          this.$refs.header.style.transform = `translateY(${-this.pageScroll / 6}px)`
        }
        if(this.$refs.img) (
          this.$refs.img.style.transform = `translate(-50%, ${this.pageScroll / 2}px)`
        )

      },
    }
}
</script>

<style lang="scss">
.header {
    color: white;


    .logo {
      position: absolute;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%);
      top: 2rem;
    }

    & ul {
      @include fontPoppins;
      font-size: 13px;
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-bottom: 14px;
     
      & li {
        margin-right: 60px;
        list-style: none;
        position: relative;
        cursor: default;
      }
    }


    &__banner {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 14rem 0px 6rem;
      overflow: hidden;

      & img {
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        position: absolute;
        top: 0;
        overflow: hidden;
        height: 100%;

          position: absolute;
          object-fit: cover;
          object-position: 10%;
          filter: grayscale(100%) brightness(50%);
      }

      & h1 {
        position: relative;
        @include fontEraox;
        width: 100%;
        font-size: 4rem;
        font-weight: 500;
        padding: 0px 2rem;
        // background-size: cover;
        background-image: url('~@/assets/img/studio.jpg');
        background-position: top;
        background-clip: text;
        -webkit-background-clip: text;
        letter-spacing: 0.05em;
        -webkit-text-fill-color: #0000;
        text-align: center;
        font-weight: 900;

        @include mq($from: mobile) {
        font-size: 5rem;
        background-size: 107%;
        background-position: -7% 11%;
        background-attachment: fixed;

        filter: brightness(100%);
        }

        @include mq($from: desktop) {
          font-size: 9rem;     
        }

        @include mq($from: wide) {
          font-size: 10rem;
        }
      }
    }


}
</style>