<template>
    <section class="licensions" id="licensions">
        <h2>Pricing</h2>
        <div class="licensions__container">
            <div class="licensions__options">
                <ul>
                    <li v-for="({title, price}, key) in lics" :key="title" @click="changeLicension($event)" :class="{ licenstions__item: true, current:  key == 1}" :data-lic="key + 1">
                        <p><span>{{ title }}</span> ${{ dollarToDollar(price) }}</p>
                    </li>
                </ul>
            </div>
            <div class="licensions__board">
                <div class="licensions__board__main">
                    <transition-group name="fade">
                        <ul v-for="(lic, key) in lics" :key="lic.title" v-show="current === key + 1">
                            <li v-for="(text, key) in lic.description.split('</br>')" :key="key">
                            {{ text }}
                            </li>
                        </ul>
                    </transition-group>
                </div>
                <div class="licensions__board__card">
                    <transition-group name="fade">
                        <h5 v-for="(lic, key) in lics" :key="lic.title" v-show="current === key + 1">{{ lic.title }}</h5>
                    </transition-group>

                    <transition-group name="fade">
                        <div v-for="({title, price}, key) in lics" :key="title" class="licensions__board__card__prices" v-show="current === key + 1">
                            <h6>{{ dollarToDollar(price) }} <span>$</span></h6>
                            <h6>{{ dollarToEuro(price) }} <span>€</span></h6>
                            <h6>{{ dollarToPLN(price) }} <span>zł</span></h6>  
                        </div>
                    </transition-group>
                </div>
                <div class="licensions__board__info">
                    <h5>Special offer</h5>
                    <ul>
                        <li>Buy 3 <br/><b>Get 4</b></li>
                        <li>Buy 4 <br/><b>Get 6</b></li>
                        <li>Buy 5 <br/><b>Get 8</b></li>
                        <li>Buy 6 <br/><b>Get 10</b></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { LicensionsService } from "@/api";
import currencyExchange from '@/mixins/currency'


export default {
    name: 'Licensions',
    mixins: [currencyExchange],
    data() {
        return {
            current: 2,
            lics: Array,
        }
    },
    mounted() {
        this.getLicensions();
    },
    methods: {
        async getLicensions() {
            await LicensionsService.getAll()
                .then(res => {        
                    this.lics = res;
                })
        },
        toggleCheck() {
            this.isChecked = true
        },
        changeLicension(e) {
            document.querySelectorAll('.licenstions__item').forEach(item =>{
                item.classList.remove('current')
            })

            e.currentTarget.classList.add('current')
            this.current = +e.currentTarget.getAttribute('data-lic')
        
        }
    }
}
</script>

<style lang="scss" scoped>

.fade-enter, .fade-enter-active, .fade-enter.to {
    opacity: 0;    
}

.fade-leave-to {
    opacity: 0;
}

.licensions {
    position: relative;
    width: 86%;
    max-width: 1200px;
    margin: 12rem auto 18rem auto;

    @include mq($to: mobile) {
        width: 92%;
    }

    @include mq($from: tablet) {
        margin: 12rem auto 6rem auto;
    }


    & h2 {
        @include fontPoppins;
        color: $lightText;
        font-size: 3rem;
        letter-spacing: .1em;
        text-align: center;
        opacity: 0.5;


        @include mq($to: mobile) {
            font-size: 3rem;
            top: 0;
            letter-spacing: 8px;
            width: 100%;
            transform: scaleY(0.9);
        }
    }

    &__container {
        margin: 60px auto 0 auto;
        width: 100%;
        height: 500px;
        max-width: 1080px;

        @include mq($to: desktop) {
            min-width: 100%;
            margin: 50px auto 0 auto;
        }

        @include mq($to: tablet) {
            width: 100%;
        }

        @include mq($to: mobile) {
            margin: 30px auto 0 auto;
        }
    }

    &__options {
        height: 60px;
        width: calc(100% - 160px);
        margin: 0 auto;

        @include mq($to: desktop) {
            width: calc(100% - 80px);      
        }

        @include mq($to: tablet) {
            width: 92%;
        }

        & ul {
            display: flex;
            flex-direction: row;
            justify-content: center;
            height: 100%;

            & li {
                width: 30%;
                height: 100%;
                padding: 10px 0;
                list-style: none;
                background-color: transparent;
                border-radius: 6px 6px 0 0;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                cursor: pointer;

                @include mq($from: mobile) {
                    width: 25%;
                }


                & p {
                    @include fontPoppins;
                    color: white;
                    text-align: center;
                    display: none;

                    @include mq($to: mobile) {
                        font-size: 12px;
                    }

                    span {
                        display: block;
                        color: white;
                        font-size: 12px;
                        margin-bottom: 0.2rem;

                        @include mq($from: mobile) {
                            display: inline;
                        }
                    }
                }

                & p:first-of-type {
                    color: $main;
                    display: block;
                }
            }

            .current {
                background-color: rgba(0,0,0,0.5);
            }
        }
    }

    &__board {
        width: 100%;
        background-color: transparent;
        border-radius: 10px;
        position: relative;

        @include mq($to: tablet) {
            width: 100%;
            margin: 0 auto;
        }

        &__main {
            width: 100%;
            height: 400px;
            background-color: $lightBox;
            border-radius: 10px;
            @include fontPoppins;
            color: $lightText;
            font-weight: 400;
            background: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(255,255,255,0) 96%);
            
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0.2;
            }

            @include mq($to: tablet) {
                display: flex;
                justify-content: center;
                text-align: center;
                border-radius: 10px 10px 0 0 ;
                background: linear-gradient(180deg, rgba(0,0,0,0.8099614845938375) 0%, rgba(255,255,255,0) 96%);
            }


            & ul {
                position: absolute;
                left: 0;
                height: 100%;
                width: 60%;
                padding: 70px 80px;
                display: flex;
                flex-direction: column;
                transition: opacity 0.16s;

                @include mq($to: desktop) {
                    width: 50%;
                    padding: 40px 4rem;
                }

                @include mq($to: tablet) {
                    width: 100%;
                }

             
                & li {
                    list-style: none;
                    position: relative;
                    margin-bottom: 3rem;

                    @include mq($from: tablet) {
                        margin-bottom: 4rem;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        top: 6px;
                        left: -16px;
                        width: 6px;
                        height: 6px;
                        background-color: transparent;

                        @include mq($to: tablet) {
                            display: none;
                        }
                    }
                }
            }
        }

        &__card {
            position: absolute;
            top: 14%;
            right: 80px;
            width: 30rem;
            height: 38rem;
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 12px 12px 0 0;
            @include fontPoppins;
            padding: 4rem 3rem;

            @include mq($to: desktop) {
                right: 4rem;
            }

            @include mq($to: tablet) {
                position: relative;
                height: 30rem;
                right: 0;
                width: 50.01%;
                border-radius: 0 0 0 12px;
                background: linear-gradient(360deg, rgba(0, 0, 0, 0.809961) 0%, rgba(255, 255, 255, 0) 96%);
                transform: translateY(-10rem);
            }

            h5 {
                font-size: 3rem;
                font-weight: 100;
                color: $mainDark;
                text-align: center;
                transition: opacity 0.16s;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);

                @include mq($to: mobile) {
                    font-size: 2.6rem;
                    margin-top: 2.6rem;
                }
            }


            &::after, &::before {
                content: '';
                position: absolute;
                bottom: -50px;
                left: 0;
                width: 0;
                height: 0;
                border-left: 150px solid transparent;
                border-right: 150px solid transparent;
                border-top: 50px solid rgba(0, 0, 0, 0.32);

                @include mq($to: tablet) {
                    display: none;
                }
            }

            &__currency {
                position: absolute;
                left: 50%;
                transform: translate(-50%, 12px);
                white-space: nowrap;
                @include fontPoppins;
            }

            &__prices {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 30px;
                color: $lightText;
                margin-top: 8rem;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                transition: opacity 0.16s;

                h6 {
                    margin-bottom: 2rem;
                    font-size: 2.4rem;
                    font-weight: 200;

                    @include mq($to: mobile) {
                        font-size: 1.8rem;
                    }

                    & span {
                        color: $mainDark;
                    }
                }
            }

            & p {
                margin-top: 10px;
                font-size: 20px;
                text-align: center;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }


        }

        &__info {
            @include mq($to: tablet) {
                position: absolute;
                bottom: 0;
                right: 0;
                background: linear-gradient(360deg, rgba(0, 0, 0, 0.809961) 0%, rgba(255, 255, 255, 0) 96%);
                 border-radius: 0 0 12px 0;
                height: 30rem;
                width: 50%;
                transform: translateY(-10rem);
            }

            h5 {
                font-family: 'Poppins';
                white-space: nowrap;
                font-size: 3rem;
                font-weight: 100;
                color: $mainDark;
                text-align: center;
                position: relative;
                margin-top: 3.2rem;
                display: none;

                @include mq($to: tablet) {
                    display: block;
                }

                 @include mq($to: mobile) {
                    font-size: 2rem;
                    margin-top: 6.7rem;

                }
            }

            & ul {
                width: calc(100% - 160px);
                display: flex;
                flex-direction: row;
                margin: 16px auto 0 auto;
                justify-content: flex-start;
                align-items: center;

                @include mq($to: desktop) {
                    width: calc(100% - 80px);
                }

                @include mq($to: tablet) {
                    height: unset;
                    width: 100%;
                    margin: 0;
                    flex-direction: column;
                    justify-content: flex-start;
                    margin-top: 4rem
                }

                & li {
                    @include fontPoppins;
                    font-weight: 200;
                    font-size: 1.3rem;
                    list-style: none;
                    margin: 0 4rem 0 0; 

                    @include mq($to: tablet) {
                        display: inline-flex;
                        width: auto;
                        margin-bottom: 1.6rem;
                        color: white;
                    }

                    @include mq($to: mobile) {
                        text-align: center;
                        font-size: 1.1rem;
                        margin-bottom: .8rem;
                    }

                    
                }

                & b {
                    color: $mainDark;

                    @include mq($to: tablet) {
                        margin-left: 1rem;
                        color: $mainDark;
                    }
                }
            }
        }
    }
    
}

</style>

