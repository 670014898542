<template>
  <section class="list" id="list">
        <ul class="list__tags" ref="tags">
            <li @click="setBeats($event.target.innerText)" :class="{active: tag == 'all'}" ref="tag">all</li>
            <li @click="setBeats($event.target.innerText)" :class="{active: tag == tagObj}" ref="tag" v-for="tagObj in tags" :key="tagObj">{{tagObj}}</li>
        </ul>
        <div class="beats">
        <ul>
            <Beat v-for="beat in beats.reverse()" :key="beat.name" :beat="beat" ref="beat"/>
        </ul>
        </div>

  </section>

</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Beat from '@/components/Beat'

export default {
    name: "List",
    components: {
        Beat
    },
    data() {
        return {
            isTagsOpen: false,
            tag: 'all'
        }
    },
    computed: {
        ...mapState({
            allBeats: (state) => state.beats.all,
        }),
        ...mapGetters('player',['getClickedID']),    
        beats() {
            const arr = [];
            this.allBeats ? this.allBeats.forEach(beat => {
                beat.tags.forEach(tag => {
                    if(tag === this.tag || this.tag === 'all') {
                        arr.push(beat)
                    }
                })
            }) : []

            return [...new Set(arr)]
        },
        tags() {
            const allTags = this.allBeats ? this.allBeats.map(beat => {
                return beat.tags
            }) : []
            return new Set(allTags.flat());
        }    
    },

    watch: {
        getClickedID() {
            this.$refs.beat?.forEach(beat => {
                beat._data.isPlaying = false
            })
        },
    },
    methods: {
        setBeats(e) {
            this.tag = e
        },
    },
}
</script>

<style lang="scss" scoped>
.list {
  @include sectionStarter;
  color: $middleText;
  margin-top: 40px;

    &__tags {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        overflow: hidden;
        @include fontPoppins;
        font-family: 'Poppins';
        column-gap: 2rem;
        row-gap: 1rem;
        margin-bottom: 2rem;

        & li {
            color: $middleText;
            list-style: none;
            cursor: pointer;
            opacity: 0.7;
            transition: color .16s, opacity .16s;

            &:hover {
                opacity: 1;
            }

            &.active {
                color: $main;
                pointer-events: none;
                cursor: default;
            }
        }

    } 
}

.beats {
    @include sectionStarter;
    width: 100%;

    @include mq($to: mobile) {
        width: 100%;
    }

    & ul {
        width: 100%;
        @include fontPoppins;
        font-weight: 300;
        color: $middleText;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;

        & li {
            margin-right: 0;
        }
    }
} 

</style>