<template>
        <li class="beat" :class="{is_playing: getClickedID === beat.id, not_from_home: !fromHome}">
            <div class="beat__img">
                <img :src="beat.image" alt="Thumbnail of beat"/>
                <button class="beat__img__play">
                        <icon-base width="33" height="38" iconColor="grey" viewBox="0 0 33 38" ><icon-play :isPlaying="isIconPlay" stroke="lightGrey"/></icon-base>
                        <span @click="playBeat" class="beat__img__play__overlay"></span>
                </button>

                <button 
                    @click="playBeat"
                    class="beat__img__stop"
                    :class="{is_active: isIconPlay}">
                </button>
            </div>

            <ul class="beat__content">
                <li @click="changePath" class="beat__content__name">{{beat.name}}</li>
                <ul class="beat__content__details">
                    <li v-show="fromHome">{{beat.time}}</li>
                    <li>{{beat.tempo}} BPM</li>
                    <li v-show="fromHome">{{beat.tune}}</li>
                </ul>
                <ul class="beat__content__tags" v-if="fromHome">
                    <li @click="setBeats($event)" v-for="tag in beat.tags" :key="tag">#{{tag}}</li>
                </ul>
            </ul>

            <div class="beat__buttons" v-if="fromHome">
                <button @click="changePath"><icon-base width="24" height="24" iconColor="grey" viewBox="0 0 500 500" ><icon-cart/></icon-base></button>
                <button @click="this.toggleFavourite"><icon-base width="24" height="24" iconColor="grey" viewBox="0 0 500 500" ><icon-hearth :isFavourite="isFavourite"/></icon-base></button>
            </div>
        </li>
</template>

<script>
import IconBase from '@/assets/icons/IconBase.vue';
import IconPlay from '@/assets/icons/IconPlay'
import IconCart from '@/assets/icons/IconCart.vue';
import IconHearth from '@/assets/icons/IconHearth.vue';
import { mapGetters } from 'vuex';

export default {
    name: "Beat",
    components: {
        IconBase,
        IconPlay,
        IconCart,
        IconHearth
    },
    data() {
        return {
            isPlaying: false,
            // isFavourite: undefined,
        }
    },
    props: {
        beat: {
            type: Object,
            required: true
        },
        fromHome: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapGetters('player',['getCurrBeat']),
        ...mapGetters('player',['getIsPlaying']),
        ...mapGetters('player',['getClickedID']),
        ...mapGetters('favourites',['getFavourites']),
        isFavourite() {
            return this.getFavourites.includes(this.beat.id)
        },
        isIconPlay(){
            return this.getClickedID === this.beat.id && this.getIsPlaying
        },
    },
    methods: {
        changePath() {
            this.$router.push({path: `/beat/${this.beat.slug}`, params: {name: this.beat.slug}})
        },
        setBeats(e) {
            this.$parent.setBeats(e.target.innerText.substring(1))
        },
        toggleFavourite(){
            const action = this.isFavourite ? "favourites/removeFavourite" : "favourites/addFavourite"
            this.$store.dispatch(action, this.beat.id);
        },
        playBeat(){
            this.isPlaying = !this.isPlaying
          
            this.updateStoreCurrentBeat();
            this.updateStorePlayingState();         
        },
        updateStoreCurrentBeat() {
            this.$store.dispatch("player/setCurrBeat", this.beat);

        },
        updateStorePlayingState() {
              this.$store.dispatch("player/setIsPlaying", {
                isPlaying: this.isPlaying,
                clickedID: this.beat.id
            });
        }
    },
}
</script>

<style lang="scss" scoped>
        .beat {
            position: relative;
            display: flex;
            align-items: center;
            list-style: none;
            width: 48%;
            font-size: 14px;
            height: 12rem;
            margin-bottom: 2rem;
            z-index: 0;
            border-radius: 20px;
            cursor: default!important;
            transition: opacity 1.4s, filter 0.3s;

            @include mq($to: tablet) {
                width: 100%;
            margin-bottom: 0rem;
                
            }

            &::before {
                content: '';
                position: absolute;
                background-color: rgba(0, 0, 0, 0.1);
                width: calc(100% + 32px);
                left: -16px;
                top: 0;
                height: 100%;
                z-index: -1;
                opacity: 0;
                transition: opacity 0.12s;
                border-radius: 20px;
            }

            &:hover {
                &::before {
                    opacity: 1;
                }
            }

        

            &__img {
                position: relative;
                min-width: 8rem;
                max-width: 8rem;
                height: 8rem;

                & img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 6px;
                    filter: brightness(50%);
                }

                @include playStopBtn;

                &__play {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    margin-left: 0;
                    margin-top: 0;
                    cursor: default;

                    &__overlay{
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            height: 8rem;
                            width: 8rem;
                            background-color: transparent;
                            cursor: pointer;
                    }
                }

                &__stop {
                    width: 8rem;
                    height: 8rem;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    &::before, &::after {
                        transform: translate(-50%, -50%);
                        background-color: lightgrey;
                    }
                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                color: white;
                width: auto;
                width: 100%;
                height: 8rem;
                justify-content: space-around;
                padding-left: 20px;
                min-width: 56%;
                


                & li {
                    list-style: none;
                }

                &__name {
                    height: 2rem;
                    width: 50%;
                    list-style: none;
                    font-weight: 500;
                    font-size: 1.8rem;
                    cursor: pointer;
                    color: $almound;
                    white-space: nowrap;
                }

                &__details{
                    font-size: 14px;
                    display: flex;
                    flex-direction: row;
                         
                    
                    & li {
                        margin-right: 20px;
                        color: $middleText;
                        white-space: nowrap;
                    }
                }

                &__tags {
                    font-size: 10px;
                    font-weight: 100;
                    letter-spacing: 1px;
                    transform: translateX(-2px);
                    display: flex;
                    flex-direction: row;
                    opacity: 1;

                    & li {
                        margin-right: 20px;
                        background-color: $box;
                        color: $middleText;
                        padding: 3px 5px;
                        border-radius: 6px;
                        cursor: pointer;
                        
                    }
                }


            }

            &__buttons {
                height: 8rem;
                width: 6rem;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                transform: translateX(12px);

                & button {
                    background-color: transparent;
                    border: none;
                    width: 48px;
                    height: 48px;
                    outline: none;
                    cursor: pointer;
                    justify-content: center;
                    align-items: center;
                    display: flex!important;

                    &:hover {
                        svg g {
                            transition: fill .22s;
                            fill: $main;
                        }
                    }
                }
            }
}

.is_playing {
    filter: brightness(200%);
}

.not_from_home {
    height: 40px!important;
    padding: 0!important;
    margin: 0!important;
    font-weight: 200;

    & img {
        height: 4rem;
        width: 4rem;
        filter: brightness(40%);
    }

    .beat__img__play {
        margin: 0;
        margin-top: 14px;
    }

    .beat__img__stop {
        width: 20px;
        height: 20px;
        top: 8px;
        left: -8px;

        &::before, &::after {
            background-color: lightgrey;
            width: 4px;
        }
    }

    .beat__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 4rem;
        cursor: pointer;

        & li {
            width: auto;
        }

        & ul {
            margin-left: 32px;
            margin-top: 6px;
        }
    }
}
</style>