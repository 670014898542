<template>
    <section class="youtube">

     <h2>Youtube statistics</h2>

      <div class="youtube__info">
        <ul>
            <li>
                <div class="youtube__info__text">
                    <div class="youtube__info__text__up">Subscribers</div>
                    <div class="youtube__info__text__down">{{ getData.subs }}</div>
                </div>
            </li>
            <li>
             
                <div class="youtube__info__text">
                    <div class="youtube__info__text__up">Total Views</div>

                    <div class="youtube__info__text__down">{{ getData.views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }}</div>
                </div>
            </li>
            <li>
     
                <div class="youtube__info__text">
                    <div class="youtube__info__text__up">Videos</div>
                    <div class="youtube__info__text__down">{{ getData.videos }}</div>
                </div>
            </li>
        </ul>
      </div>
      


  </section>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
    name: "Youtube",

    computed: {
        ...mapGetters('youtube',['getData']),
    },
    mounted() {
        if(!this.getData.subs) {
            this.$store.dispatch("youtube/getData");
        }
    }
}
</script>

<style lang="scss" scoped>
.youtube {
    width: 62%;
    max-width: 720px;
    margin: 0 auto;
    padding: 160px 0px 160px;

    @include mq($to: tablet) {
    padding: 100px 0px 72px;
        flex-direction: column;
    }

    @include mq($to: mobile) {
        width: 92%;
    }

    h2 {
        @include fontPoppins;
        font-size: 3rem;
        text-align: center;
        margin-bottom: 4rem;
        color: $main;
        opacity: 0.8;
    }

    &__info {
        width: 100%;


        & ul {
            display: flex;
                flex-direction: column;
            justify-content: space-between;
            gap: 3rem;
            height: 100%;

            @include mq($from: mobile) {
                flex-direction: row;
            }

            & li {
                display: flex;
                flex-direction: row;
                justify-content: center;
                width: 100%;
                white-space: nowrap;
            }
        }

        &__text {
            display: flex;
            flex-direction: column;
            @include fontPoppins;
            margin: auto 0;
            text-align: center;
            color: $middleText;

            &__up {
                font-size: 14px;
                margin-bottom: 8px;
                color: $darkText;
            }

            &__down {
                font-size: 22px;
            }
        }
    }
}
</style>