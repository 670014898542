<template>
  <section>
    <h2>Featured</h2>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </section>
</template>

<script>
export default {
    name: "Featured"
}
</script>

<style lang="scss" scoped>
section {
  @include sectionStarter;
  border:2px solid darkcyan;
  height: 24rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  flex-flow: wrap;
  margin-top: 3rem;
  margin-bottom: 3rem;
  
  & h2 {
    width: 100%;
    font-size: 30px;
  }

  & span {
    width: 20%;
    height: 80%;
    border: 2px solid darkgoldenrod;
  }
}
</style>